<template>
  <b-container>
    <h4 class="text-center mb-4">{{ $t('timesheet.current-overtime') }}</h4>
    <TimesheetOverview :user-id="$store.getters.getCurrentUser.id" />

    <h4 class="text-center mt-5 mb-4">{{ $t('timesheet.flextime-account') }}</h4>
    <TimesheetFlextimeOverview :user-id="$store.getters.getCurrentUser.id" />

    <h4 class="text-center mt-5 mb-4">{{ $t('timesheet.flextime-corrections') }}</h4>
    <TimesheetFlextimeCorrectionManagement :user-id="$store.getters.getCurrentUser.id" :show-actions="false" />

    <h4 class="text-center mt-5 mb-4">{{ $t('timesheet.monthly-view') }}</h4>
    <TimesheetMonthChart :user-id="$store.getters.getCurrentUser.id" />
  </b-container>
</template>

<script>
import TimesheetOverview from '@/components/timesheet/TimesheetOverview'
import TimesheetMonthChart from '@/components/timesheet/TimesheetMonthChart'
import TimesheetFlextimeOverview from '@/components/timesheet/TimesheetFlextimeOverview'
import TimesheetFlextimeCorrectionManagement from '@/components/timesheet/TimesheetFlextimeCorrectionManagement'

export default {
  name: 'AccountTimesheet',
  components: {
    TimesheetOverview,
    TimesheetMonthChart,
    TimesheetFlextimeOverview,
    TimesheetFlextimeCorrectionManagement
  }
}
</script>
